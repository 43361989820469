import NavBar from "../components/nav-bar";

function Home() {
    return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <NavBar />
                    </div>
                </div>
            </div>
    );
}

export default Home;
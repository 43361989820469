import { useEffect } from 'react';

const TarteaucitronComponent = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://tarteaucitron.io/load.js?domain=${process.env.REACT_APP_TARTEAUCITRON_DOMAIN}&uuid=${process.env.REACT_APP_TARTEAUCITRON_UUID}`;
        script.async = true;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return null;
};

export default TarteaucitronComponent;

import Home from "./pages/home";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Adhesion from "./pages/adhesion";
import 'bootstrap/dist/css/bootstrap.min.css';
import HorizontalLinearStepper from "./pages/stepper";
import Memori from "./pages/memori";


function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/adhesion" element={<Adhesion />} />
          <Route path="/referencement/:siret?" element={<HorizontalLinearStepper />} />
          <Route path="/memori" element={<Memori />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;

import React, {useEffect, useState} from "react";
import {Step, Stepper} from "react-form-stepper";
import {ListGroup, Modal} from "react-bootstrap";
import StepWizard from "react-step-wizard";
import {Button, FormGroup, Label} from "reactstrap";
import {Controller, useForm} from "react-hook-form";
import {Autocomplete, Grid, styled, TextField} from "@mui/material";
import 'dayjs/locale/fr';
import axios from "axios";
import {wait} from "@testing-library/user-event/dist/utils";
import {withHookFormMask} from "use-mask-input";
import {PatternFormat} from "react-number-format";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";

const ActionButtons = (props) => {
    const handleBack = () => {
        props.previousStep();
    };

    const handleNext = () => {
        props.nextStep();
    };

    //const handleFinish = () => {
    //    props.lastStep();
    //};

    return (
        <div className="col-12">
            <div className="m-4 d-flex justify-content-between">
                {props.currentStep > 1 && (
                    <Button  onClick={handleBack}>Précédent</Button>
                )}
                {props.currentStep < props.totalSteps && (
                    <Button className="btn primary" disabled={!props.stepOK}  type="submit" onClick={handleNext}>Suivant</Button>
                )}
                {props.currentStep === props.totalSteps && (
                    <Button type="submit" >Envoyer</Button>
                )}
            </div>
        </div>
    );
};

const One = (props) => {
    const [options, setOptions] = useState([]);
    //const [messageSiret, setMessageSiret] = useState('');
    const [messageMembership, setMessageMembership] = useState('');
    const [inpuValue, setInputValue] = React.useState("");
    const [selectedObject, setSelectedObject] = useState({});
    const [stepOK, setStepOK] = useState(false);
    const [Notmember, setNotMember] = useState(false);
    const [unknownSiret, setUnknownSiret] = useState(false);


    const MembershipStatusEnum = {
        NonMember : 1,
        CancelledMember : 2,
        OldMember : 3,
        WaitingMember : 4,
        InvoicedMember : 5,
        FreeMember : 6,
        PaidMember : 7,
    };

    const validate = () => {
        window.scrollTo(0,0);
        props.nextStep();
    };

    const handleSelect = (data) => {
        setSelectedObject(data);
        //console.log(data);
        handleMemebershipStatut(data);
        props.structureCallback(data);
    }

    const handleChange =  event => {
        setUnknownSiret(false);
        setNotMember(false);
        props.formTitleCallback('Adhésion');
        setMessageMembership("");
        //setMessageSiret("");
        setStepOK(false);
        setOptions([]);
        setSelectedObject({});
        let siret = event.target.value;
        setInputValue(event.target.value);
        if (siret.length === 14 && EstSiretValide(siret) === false)
        {
            setUnknownSiret(true);
            //setMessageSiret("Aucun résultat : le SIRET est incorrect, veuillez corriger la saisie pour passer à l'étape suivante")
        }
        else if (siret.length === 14 && EstSiretValide(siret)) {
            fetch(`${process.env.REACT_APP_API_SOURCE}/api/v1.0/ResPartnerOdoo/odoo/structure/${event.target.value}`)
                .then(response => response.json())
                .then(responseData => {
                    if (responseData.status) {
                        //console.log(responseData);
                        setOptions(responseData.structures);
                    }

                    else {
                        setNotMember(true);
                        //setMessageSiret("Votre structure n'est pas référencée à CAP Métiers <a href='/referencement' target='_blank'>referencement</a> ")
                        //console.log(responseData);
                        setOptions([]);
                    }
                })
                .catch(error => {
                    //console.log('Error fetching and parsing data', error);
                });
        }
    }

    const handleMemebershipStatut = (data) => {
        props.formTitleCallback('Adhésion');

        let membershipStopDate = new Date(data.membershipStop);
        let membership_stop_year = membershipStopDate.getFullYear();
        let anneeRenouvellement = membership_stop_year;
        let currentTime = new Date();
        let currentyear = currentTime.getFullYear();
        let nextyear = currentTime.getFullYear() + 1;
        let NextDecemberAfterStopDate = new Date(membershipStopDate.getFullYear() - 1 + '-12-01');
        let abonneNextYear = membershipStopDate.getFullYear() === currentTime.getFullYear();
        const dec1 = new Date(currentyear, 11, 1); // Les dates sont indexés à partir de 0

        switch (data.membershipState) {
            case MembershipStatusEnum.NonMember:
                if (currentTime.getMonth() !== 12) {
                    props.formTitleCallback("Formulaire d’adhésion " + currentyear);
                    setStepOK(true);
                }else {
                    props.formTitleCallback("Formulaire d’adhésion " + nextyear); // sur décembre on s'inscrit l'année d'après
                    setStepOK(true);
                }
                break;
            case MembershipStatusEnum.CancelledMember:
                if (currentTime.getTime() !== 12) {
                    props.formTitleCallback("Formulaire d’adhésion " + currentyear);
                    setStepOK(true);

                }else {
                    props.formTitleCallback("Formulaire d’adhésion " + nextyear);
                    setStepOK(true);
                }
                break;

            case MembershipStatusEnum.WaitingMember:
                setMessageMembership("Votre demande d’adhésion est en attente de validation, nous reviendrons vers vous dès qu’elle sera validée.");
                setStepOK(false);
                props.formTitleCallback('Adhésion');
                break;
            case MembershipStatusEnum.InvoicedMember:
                setMessageMembership("Votre demande d’adhésion est en attente de règlement. Dès sa réception, votre adhésion sera effective.");
                setStepOK(false);
                props.formTitleCallback('Adhésion');
                break;
            case MembershipStatusEnum.FreeMember:
                setMessageMembership("Votre structure est membre de droit de Cap Métiers, il n'est pas nécessaire que vous demandiez à adhérer.");
                setStepOK(false);
                props.formTitleCallback('Adhésion');
                break;
            case MembershipStatusEnum.PaidMember:
                if (currentTime.getTime() < NextDecemberAfterStopDate.getTime()) {
                    setMessageMembership("Vous êtes déja adhérent à Cap Métiers pour cette année. A partir du 1er décembre, vous pourrez demander à renouveler votre adhésion pour l'année prochaine.");
                    setStepOK(false);
                    props.formTitleCallback('Adhésion');
                } else {
                    props.formTitleCallback("Formulaire de renouvellement d’adhésion " + anneeRenouvellement);
                    setStepOK(true);

                }
                break;
            case MembershipStatusEnum.OldMember:
                if (
                 currentTime.getTime() < NextDecemberAfterStopDate.getTime()) {
                    props.formTitleCallback("Formulaire de renouvellement d’adhésion " + currentyear);
                    setStepOK(true);
                } else if (abonneNextYear && currentTime.getTime() < dec1.getTime()){
                    props.formTitleCallback("Formulaire de renouvellement d’adhésion " + currentyear);
                    setStepOK(true);
                }
                else {
                    props.formTitleCallback("Formulaire d’adhésion " + (currentTime.getMonth() === 12 ? nextyear : currentyear));
                    setStepOK(true);
                }
                break;
        }

    }

    const Demo = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.background.blue,
    }));

    function EstSiretValide(siret) {
        let estValide;
        if ( (siret.length !== 14) || (isNaN(siret)) )
            estValide = false;
        else {
            let somme = 0;
            let tmp;
            for (let cpt = 0; cpt<siret.length; cpt++) {
                if ((cpt % 2) === 0) { // Les positions impaires : 1er, 3è, 5è, etc...
                    tmp = siret.charAt(cpt) * 2; // On le multiplie par 2
                    if (tmp > 9)
                        tmp -= 9;	// Si le résultat est supérieur à 9, on lui soustrait 9
                }
                else
                    tmp = siret.charAt(cpt);
                somme += parseInt(tmp);
            }
            if ((somme % 10) === 0)
                estValide = true; // Si la somme est un multiple de 10 alors le SIRET est valide
            else
                estValide = false;
        }
        return estValide;
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="mt-3 justify-content-center">
                        <PatternFormat
                            fullWidth
                            customInput={TextField}
                            size="small"
                            onChange={handleChange}
                            onPaste={handleChange}
                            label="Saisissez votre Siret puis sélectionnez votre structure"
                            variant="outlined"
                            format="##############"
                        />
                    </div>

                    <ul className="mt-3 text-help-siren">
                        <li>Le numéro Siret est un identifiant à 14 chiffres (exemple : 12002701600357)</li>
                    </ul>
                    <div className="text-center mt-3 mb-3">
                        {inpuValue.length === 14 && options.length === 0 &&  (
                             Notmember &&
                            <div className="bg-light p-3">
                                <h2>Votre structure n'est pas référencée à Cap Métiers ! </h2>
                                <p> Nous vous proposons de renseigner le formulaire de référencement en cliquant sur le bouton ci-dessous </p>
                                <a rel="noreferrer" target="_blank" href={"/referencement/" + inpuValue} className="btn btn-primary primary">Référencer ma structure </a>
                            </div>



                        )}

                        {inpuValue.length === 14 && options.length === 0 &&  (
                            unknownSiret &&  <p>Aucun résultat : le SIRET est incorrect, veuillez corriger la saisie pour passer à l'étape suivante</p>
                        )}

                        {selectedObject && options.length !== 0 && (
                            <span>{selectedObject.name} - {selectedObject.siret}</span>
                        ) }
                    </div>

                    <div><p className="messageMembership">{messageMembership}</p></div>

                    <Grid item xs={12} md={6}>
                        <Demo>
                            <ListGroup>
                                {options.map((result) =>
                                    <ListGroup.Item
                                        onClick={() => handleSelect(result)}
                                        action as="li" className="siren-item d-flex justify-content-between align-items-start" >
                                        <div key={result.siret} className="ms-2 me-auto">
                                            <div className="fw-bold">{result.siret}</div>
                                            {result.name}
                                        </div>
                                    </ListGroup.Item>
                                )}
                            </ListGroup>
                        </Demo>
                    </Grid>
                </div>
            </div>
            <div className="row">
                <ActionButtons  {...props} stepOK={stepOK}  nextStep={validate} />
            </div>
        </div>
    );
};


const Two = (props) => {
    const [stepOK, setStepOK] = useState(true);
    const [fonction, setFonction] = useState({});
    const [lastname, setLastName] = useState("");
    const [firstaname, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [title, setTitle] = useState();
    const [phone, setPhone] = useState("");
    const [mobile, setMobile] = useState("");
    const [defaultFunction, setDefaultFunction] = useState({});
    const [fonctionOptions, setFonctionOptions] = useState([])
    const [productOptions, SetProductOptions] = useState([])
    const { register, handleSubmit,trigger,reset, watch,control, formState: { errors } } = useForm({
        mode: 'onChange'
    });

    const madame = 1;
    const monsieur = 3;

    useEffect( () => {
        let defaultValues = {};

        async function fetchData() {
            setFonctionOptions(props.functions);
            SetProductOptions(props.catalogProduct);
            let defaultValues = {};
            defaultValues.structureInstanceReferentId = props.structure.structureInstanceReferentId;

            if (defaultValues.structureInstanceReferentId !== undefined && defaultValues.structureInstanceReferentId !== null) {

                try {
                    let res = await axios(`${process.env.REACT_APP_API_SOURCE}/api/v1.0/ResPartnerOdoo/contact/${defaultValues.structureInstanceReferentId}`);
                    let arr = res.data.contacts;
                    defaultValues.personReferentMembershipLastname = arr[0].lastname;
                    defaultValues.PersonReferentMembershipFirstname = arr[0].firstname;
                    defaultValues.PersonReferentMembershipEmail = arr[0].email;
                    defaultValues.PersonReferentMembershipPhone = arr[0].phone;
                    defaultValues.PersonReferentMembershipMobile = arr[0].mobile;
                    defaultValues.PersonReferentMembershipTitleId = arr[0].title;
                    setDefaultFunction(props.functions.find(option => option.id === arr[0].personCatalogFunctionId));
                    setLastName(defaultValues.personReferentMembershipLastname);
                    setFirstName(defaultValues.PersonReferentMembershipFirstname);
                    setEmail(defaultValues.PersonReferentMembershipEmail);
                    setMobile(defaultValues.PersonReferentMembershipMobile);
                    setPhone(defaultValues.PersonReferentMembershipPhone);
                    setTitle(defaultValues.PersonReferentMembershipTitleId);


                } catch (err) {
                    //console.log(err)
                }

                return defaultValues;

            }
        }

        fetchData();

        reset({...defaultValues});
    }, [defaultFunction, props.catalogProduct, props.functions, props.structure, reset]);
    const onChangeHandlerFunctions = (text) => {
        setFonction(text);
    }

    const validatePhoneNumbers = () => {
        const phone = watch('PersonReferentMembershipPhone');
        const mobile = watch('PersonReferentMembershipMobile');
        return Boolean(phone || mobile) || 'Au moins un des champs téléphones doit être renseigné.';
    };

    const previous = data => {
        window.scrollTo(0,0);
        props.previousStep();
    }
    //const handleFunctionChange = (data) => {
    //    setDefaultFunction(data);
    //}


    const validate2 = async (data) => {
        await wait(700);
        
        let options = { day: 'numeric', month: 'numeric', year: 'numeric' };
        let date = new Date();
        let error = false;
        let newAdhesion = {...data};
        newAdhesion.personReferentMembershipFunctionId = fonction.id;
        newAdhesion.name = props.formTitle + " | " + props.structure.name + " | " + date.toLocaleDateString('fr-FR', options);

        newAdhesion.structureAcronym = props.structure.structureAcronym;
        newAdhesion.structureName = props.structure.name;

        newAdhesion.partnerId =  props.structure.id;
        newAdhesion.referentMembershipId = props.structure.structureInstanceReferentId !== null ? props.structure.structureInstanceReferentId : null;
        newAdhesion.structureSiret = props.structure.siret;
        newAdhesion.membershipProductId = parseInt(newAdhesion.membershipProductId);
        newAdhesion.PersonReferentMembershipTitleId = parseInt(newAdhesion.PersonReferentMembershipTitleId);
        //console.log(newAdhesion)

        if (defaultFunction && newAdhesion.personReferentMembershipFunctionId === undefined) {
            newAdhesion.personReferentMembershipFunctionId = defaultFunction.id;
        }
        
        let isValid = false;
        isValid = await trigger([
            "PersonReferentMembershipFirstname",
            "PersonReferentMembershipLastname",
            "PersonReferentMembershipMobile",
            "PersonReferentMembershipTitleId",
            "PersonReferentMembershipEmail",
            "PersonReferentMembershipPhone",
            "personReferentMembershipFunctionId",
            "StructureMembershipReason",
            "membershipProductId",
            "paymentMethod"
        ]);


        if (isValid) {
            newAdhesion.paymentMethod = parseInt(newAdhesion.paymentMethod);
            newAdhesion.PersonReferentMembershipTitleId = title;
            //console.log(newAdhesion);
            props.membershipInfosCallback(newAdhesion);
            window.scrollTo(0,0);
            props.nextStep();
        }
    };

    const handleTitleChange = (event) => {
        setTitle(Number(event.target.value));
    };

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const handleMobileChange = (event) => {
        setMobile(event.target.value);
    };

    return (
        <div className="container">
            <form onSubmit={handleSubmit(validate2)}>
            <div className="row">
                    <div className="col-md-12">
                        <fieldset className="fieldset-structure">
                            <legend className="legend-structure">Votre souhait d'adhésion</legend>
                            <div className="fieldset-wrapper">
                                <FormGroup className="mb-3" >
                                    <Label>Choisissez votre formule d’adhésion * :</Label>
                                    {
                                        productOptions.map((option) => {
                                            return (
                                                <div className="form-check mt-4">
                                                    <input
                                                        {...register('membershipProductId', { required: "Veuillez choisir une formule d'adhésion" })}
                                                        className=""
                                                        name="membershipProductId"
                                                        type="radio"
                                                        id={option.id}
                                                        value={option.id}
                                                    />
                                                    <Label for={option.id}>
                                                        &nbsp;{option.name}
                                                    </Label>
                                                </div>
                                            )
                                        })
                                    }
                                    <span className="text-danger">{errors.membershipProductId && "Veuillez choisir une formule d'adhésion"}</span>
                                </FormGroup>
                                <div className="row">
                                    <div className="col-md12">
                                    <FormGroup className="mb-3" >
                                        <Label>Vos motivations pour adhérer à Cap Métiers Nouvelle-Aquitaine. *</Label>
                                        <textarea
                                            {...register('StructureMembershipReason', {required: 'Veuillez renseigner cette rubrique'} )}
                                            name="StructureMembershipReason"
                                            placeholder="Saisir..."
                                            className="form-control"
                                        />
                                        {errors.StructureMembershipReason && <span className="text-danger">{errors.StructureMembershipReason.message}</span>}
                                    </FormGroup>
                                </div>

                                <div className="col-md-12">
                                    <Label>Choisissez votre mode de paiement * : </Label>
                                    <FormGroup check>
                                        <input
                                            {...register("paymentMethod", {required: 'Vous devez choisir un mode de paiement'}  )}
                                            name="paymentMethod"
                                            id="VIR"
                                            type="radio"
                                            value="2"
                                        />
                                        {' '}
                                        <Label check htmlFor="VIR">
                                            Virement
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <input
                                            {...register("paymentMethod", {required: 'Vous devez choisir un mode de paiement'})}
                                            name="paymentMethod"
                                            type="radio"
                                            id="CB"
                                            value="1"
                                        />
                                        {' '}
                                        <Label check htmlFor="CB">
                                            Carte bancaire
                                        </Label>
                                    </FormGroup>
                                    <span className="text-danger">{errors.paymentMethod && "Veuillez choisir une méthode de paiement"}</span>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <fieldset>
                        <legend className="legend-structure">Coordonnées du Référent adhésion</legend>
                        <div className="fieldset-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <FormGroup>
                                        <Label>Civilité * : </Label>
                                        <FormGroup check>
                                            <input
                                                {...register("PersonReferentMembershipTitleId", {required: "Ce champ est obligatoire"})}
                                                name="PersonReferentMembershipTitleId"
                                                id="monsieur"
                                                type="radio"
                                                checked={title === 3}
                                                value={monsieur}
                                                onChange={handleTitleChange}

                                            />
                                            {' '}
                                            <Label check htmlFor="monsieur">
                                                Monsieur
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <input
                                                {...register("PersonReferentMembershipTitleId", {required: "Ce champ est obligatoire"})}
                                                name="PersonReferentMembershipTitleId"
                                                checked={title === 1}
                                                type="radio"
                                                id="madame"
                                                value={madame}
                                                onChange={handleTitleChange}
                                            />
                                            {' '}
                                            <Label check htmlFor="madame">
                                                Madame
                                            </Label>
                                        </FormGroup>
                                        <span className="text-danger">{errors.PersonReferentMembershipTitleId && "Ce champ est obligatoire"}</span>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <FormGroup>
                                        <Label>Nom * : </Label>
                                        <input
                                            {...register("PersonReferentMembershipLastname", {required: "Vous devez renseigner ce champ"})}
                                            type="text"
                                            defaultValue={lastname?? ''}
                                            name="PersonReferentMembershipLastname"
                                            className="form-control"
                                        />
                                        {errors.PersonReferentMembershipLastname && <span className="text-danger">{errors.PersonReferentMembershipLastname.message}</span>}
                                    </FormGroup>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <FormGroup>
                                        <Label>Prénom * : </Label>
                                        <input
                                            {...register("PersonReferentMembershipFirstname", {required: "Vous devez renseigner ce champ"})}
                                            type="text"
                                            name="PersonReferentMembershipFirstname"
                                            defaultValue={firstaname?? ''}
                                            className="form-control"
                                        />
                                        {errors.PersonReferentMembershipFirstname && <span className="text-danger">{errors.PersonReferentMembershipFirstname.message}</span>}

                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <FormGroup>
                                        <Label>Email * : </Label>
                                        <input
                                            {...register("PersonReferentMembershipEmail", {required: "Vous devez renseigner ce champ",pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "veuillez saisir un email valide"
                                                }})}
                                            type="email"
                                            name="PersonReferentMembershipEmail"
                                            defaultValue={email?? ''}
                                            className="form-control"
                                        />
                                        {errors.PersonReferentMembershipEmail && <span className="text-danger">{errors.PersonReferentMembershipEmail.message}</span>}

                                    </FormGroup>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <FormGroup>
                                        <Label>Fonction *</Label>
                                        <Controller
                                            rules={{ required: (defaultFunction &&defaultFunction.name) ? false : 'Veuillez choisir une fonction' }}
                                            render={(props) => (
                                                <Autocomplete
                                                    {...props}
                                                    size="small"
                                                    id="tags-outlined"
                                                    options={fonctionOptions}
                                                    onInputChange={(event, newInputValue) => {
                                                        setFonction(newInputValue);

                                                    }}
                                                    getOptionLabel={(option) => option.name}
                                                    filterSelectedOptions
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...register('personReferentMembershipFunctionId')}
                                                            {...params}
                                                            label=""
                                                            onChange={e => onChangeHandlerFunctions(e.target.value) }
                                                            onFocus={e => onChangeHandlerFunctions(e.target.value)}
                                                            placeholder={(defaultFunction && defaultFunction.name)? "Mettre à jour la fonction" : "Choisir une fonction"}
                                                        />
                                                    )}
                                                    onChange={(event, newValue) => {
                                                        setFonction(newValue);
                                                    }}
                                                />
                                            )}
                                            name="personReferentMembershipFunctionId"
                                            control={control}
                                        />
                                        <div className="d-flex justify-content-center">
                                            <span><strong>{defaultFunction?.name}</strong></span>
                                        </div>
                                        {errors.personReferentMembershipFunctionId && <span className="text-danger">Veuillez choisir une fonction</span>}
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <FormGroup>
                                        <Label>Téléphone fixe  : </Label>
                                        <input
                                            type="tel"
                                            {...register('PersonReferentMembershipPhone', {validate: validatePhoneNumbers})}
                                            {...withHookFormMask(register('PersonReferentMembershipPhone',{validate: validatePhoneNumbers} ), ['99 99 99 99 99', '+99 9 99 99 99 99'])}
                                            name="PersonReferentMembershipPhone"
                                            value={phone ?? ''}
                                            className="form-control"
                                            onChange={handlePhoneChange}
                                        />
                                        {errors.PersonReferentMembershipPhone && <span className="text-danger">{errors.PersonReferentMembershipPhone.message}</span>}
                                    </FormGroup>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <FormGroup>
                                        <Label>Téléphone mobile pro  : </Label>
                                        <input
                                            {...withHookFormMask(register('PersonReferentMembershipMobile',{validate: validatePhoneNumbers} ), ['99 99 99 99 99', '+99 9 99 99 99 99'])}
                                            type="tel"
                                            name="PersonReferentMembershipMobile"
                                            value={mobile?? ''}
                                            className="form-control"
                                            onChange={handleMobileChange}
                                        />
                                        {errors.PersonReferentMembershipMobile && <span className="text-danger">{errors.PersonReferentMembershipMobile.message}</span>}
                                    </FormGroup>
                                </div>
                                <p>Au moins un des champs téléphones doit être renseigné.</p>
                                <span className="text-danger">{}</span>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <ActionButtons {...props} stepOK={stepOK}  previousStep={previous} nextStep={validate2} />
                </div>
            </div>
            </form>
        </div>
    );
};

const Three = (props) => {

    const { register, handleSubmit,trigger,reset,setValue,  formState: { errors } } = useForm({
        mode: 'onChange'
    });

    const today = new Date();
    const [defaultActivity, setDefaultActivity] = useState({});
    const [defaultCreationDate, setDefaultCreationDate] = React.useState('');

    useEffect(() => {
        let defaultValues = {};

        defaultValues.structureActivityDescription = props.structure.structureActivityDescription;

        defaultValues.structureMembershipWorkforce = props.structure.structureWorkforce === 0 ? undefined : props.structure.structureWorkforce;
        defaultValues.structureMembershipTeachingActivityPc = props.structure.structureTeachingActivityPc === 0 ? undefined : props.structure.structureTeachingActivityPc;

        if (props.structure) {
            setDefaultActivity(props.activities.find(option => option.id === props.structure.structureCatalogActivityId));
            setDefaultCreationDate(props.structure.structureCreationDate);
        }

        //console.log(props.activities);
        reset({...defaultValues})
    }, [props.structure, props.activities, reset, defaultActivity])



    //const handleChange = (newValue) => {
    //    setValue(newValue);
    //};

    //console.log(props);

    const previous = data => {
        window.scrollTo(0,0);
        props.previousStep();
    }

    const handleLastStep = async data => {
        let newAdhesionPartTow = {...data};
        newAdhesionPartTow.structureActivityDescription = newAdhesionPartTow.structureActivityDescription === '' ? props.structure.structureActivityDescription : newAdhesionPartTow.structureActivityDescription;
        newAdhesionPartTow.structureMembershipWorkforce = newAdhesionPartTow.structureMembershipWorkforce === '' ? props.structure.structureWorkforce : newAdhesionPartTow.structureMembershipWorkforce;
        newAdhesionPartTow.structureMembershipTeachingActivityPc = newAdhesionPartTow.structureMembershipWorkforce === '' ? props.structure.structureTeachingActivityPc : newAdhesionPartTow.structureMembershipTeachingActivityPc;


        let isValid = await trigger([
            "structureActivityDescription",
            "structureMembershipCatalogActivityId",
            "PersonReferentMembershipMobile",
            "structureMembershipCreationDate",
            "structureMembershipTeachingActivityPc",
            "structureMembershipWorkforce",
            "FormInfos",
            "FormInfosBis"
        ]);

        if (isValid) {
            if (defaultActivity && newAdhesionPartTow.structureMembershipCatalogActivityId === "" ) {
                newAdhesionPartTow.structureMembershipCatalogActivityId = defaultActivity.id;
            }


            let merged = {...props.membershipInfos, ...newAdhesionPartTow}
            delete merged.FormInfosBis;
            //console.log("merged", merged);
            props.lastStep();
            props.completeCallback(merged);
        }

    };

    const curr = new Date();
    curr.setDate(curr.getDate() + 3);
    //const date = curr.toISOString().substring(0,10);

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                </div>
            </div>

            <form onSubmit={handleSubmit(handleLastStep)}>
                <div className="row">
                    <div className="col-md-12">
                        <fieldset>
                            <legend className="legend-structure">Vérifier et compléter les données de votre structure </legend>
                            <div className="fieldset-wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        <FormGroup>
                                            <Label>Description détaillée de votre activité * : </Label><br />
                                            <Label>Ces informations complétées avec précisions sont nécessaires à l’instruction
                                                de votre demande et garantissent la présentation auprès de nos administrateurs, pour approbation.  </Label>
                                            <textarea
                                                {...register('structureActivityDescription', {required: 'Vous devez renseigner cette rubrique'})}
                                                name="structureActivityDescription"
                                                defaultValue={props.structure.structureActivityDescription}
                                                className="form-control"
                                            />
                                            {errors.structureActivityDescription && <span className="text-danger">{errors.structureActivityDescription.message}</span>}

                                        </FormGroup>
                                        <Label>Date de création de votre structure * : </Label>
                                        <FormGroup>
                                            <input
                                                {...register("structureMembershipCreationDate", {required: 'Ce champ est obligatoire'})}
                                                type="date"
                                                name="structureMembershipCreationDate"
                                                defaultValue={defaultCreationDate && defaultCreationDate.substring(0, 10)}
                                                className="form-control"
                                                placeholder="Choisir une date"
                                                min={"1700-01-01"}
                                                max={yesterday.toISOString().split("T")[0]}
                                            />
                                            {errors.structureMembershipCreationDate && <span className="text-danger">{errors.structureMembershipCreationDate.message}</span>}
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Effectif *</Label>
                                            <input
                                                {...register("structureMembershipWorkforce", {required: 'Vous devez renseigner ce champ'} )}
                                                className="form-control"
                                                size="small"
                                                min={1}
                                                type="number"
                                                name="structureMembershipWorkforce"
                                            />
                                            {errors.structureMembershipWorkforce && <span className="text-danger">{errors.structureMembershipWorkforce.message}</span>}
                                        </FormGroup>

                                        <FormGroup>
                                            <Label>Domaine d'activité principal *</Label>

                                            <select
                                                name="structureMembershipCatalogActivityId"
                                                {...register("structureMembershipCatalogActivityId", {required: defaultActivity ? false : 'vous devez sélectionner un élément'})}
                                                placeholder="Sélectionner..."
                                                className="form-select"
                                                aria-label="Default select example">
                                                <option value="" selected disabled>{'Sélectionner...'}</option>
                                                {props.activities && props.activities.map((suggestion, i) =>
                                                    <option key={i} value={suggestion.id}>{suggestion.name}</option>
                                                )}
                                            </select>
                                            {defaultActivity && defaultActivity.name}
                                            {errors.structureMembershipCatalogActivityId && <span className="text-danger">{errors.structureMembershipCatalogActivityId.message}</span>}
                                        </FormGroup>

                                        <FormGroup>
                                            <Label>Pourcentage de votre activité formation *</Label>
                                            <input
                                                {...register("structureMembershipTeachingActivityPc", {required: 'Vous devez renseigner ce champ'})}
                                                name="structureMembershipTeachingActivityPc"
                                                className="form-control"
                                                min={0}
                                                type="number"
                                                size="small"
                                            />
                                            {errors.structureMembershipTeachingActivityPc && <span className="text-danger">{errors.structureMembershipTeachingActivityPc.message}</span>}
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend className="legend-structure">Information traitement des données</legend>
                            <div className="fieldset-wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-check mt-4">
                                            <input
                                                {...register('FormInfos', {required: 'Merci de cocher cette case avant de valider'})}
                                                className="form-check-input"
                                                name="FormInfos"
                                                id="FormInfos"
                                                value="Je reconnais avoir pris connaissance de la charte d'adhésion et je m’engage à respecter les modalités énoncées. / En envoyant ce formulaire, j'accepte que les informations saisies soient exploitées à usage exclusif de contact pour traitement de ma demande."
                                                type="checkbox"/>
                                            <Label for="FormInfos">Je reconnais avoir pris connaissance de la <a target="_blank" rel="noreferrer" href="https://sso.aquitaine-cap-metiers.fr/files/CHARTE_ADHESION.pdf">charte d'adhésion</a>  et je m’engage à respecter les modalités énoncées. *</Label><br />
                                            {errors.FormInfos && <span className="text-danger">{errors.FormInfos.message}</span>}
                                        </div>
                                        <div className="form-check mt-4">
                                            <input
                                                {...register('FormInfosBis', {required: 'Merci de cocher cette case avant de valider'})}
                                                id="FormInfosBis"
                                                className="form-check-input"
                                                name="FormInfosBis"
                                                value="En envoyant ce formulaire, j'accepte que les informations saisies soient exploitées à usage exclusif de contact pour traitement de ma demande."
                                                type="checkbox"/>
                                            <Label for="FormInfosBis">En envoyant ce formulaire, j'accepte que les informations saisies soient exploitées à usage exclusif de contact pour traitement de ma demande. *</Label><br />
                                            {errors.FormInfosBis && <span className="text-danger">{errors.FormInfosBis.message}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <p className="text-fin-form mt-3"><span >
                            Les informations recueillies dans ce formulaire font l’objet d’un traitement informatique consistant à répondre à votre demande d’adhésion et selon nos principes de gestion d’adhésion à être soumis, le cas échéant, à nos administrateurs du Conseil d’Administration. Cap Métiers Nouvelle-Aquitaine est seule destinatrice de ces données. Dans la mesure où votre demande serait acceptée, ces données seront alors stockées dans nos systèmes d’information pour la gestion de votre structure. Elles seront conservées dans le cadre d’une durée légale de minimum 10 ans relatifs au paiement de votre cotisation (documents comptables : factures et justificatifs)

                            Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier ou effacer en envoyant votre demande par <a href="mailto:adhesion@cap-metiers.pro">mail</a>.
                        </span>
                        </p>
                    </div>
                </div>
                <div className="row">
                    <ActionButtons {...props} previousStep={previous} lastStep={handleLastStep} />
                </div>
            </form>
        </div>
    );
};

const Adhesion = () => {
    const [stepWizard, setStepWizard] = useState(null);
    const [loading, setLoading] = React.useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [structure, setStructure] = useState({});
    const [formTitle, setFormTitle] = useState('Adhésion');
    const [catalogProduct, setCatalogProduct] = useState([]);
    const [functions, setFunctions] = useState([]);
    const [activities, setActivities] = useState([]);
    const [membershipInfos, setMembershipInfos] = useState({});

    const [displayMessage, setDisplayMessage] = useState('');
    const [displayTitle, setDisplayTitle] = useState('');
    const [show, setShow] = useState(false);


    const handleCloseModal = () => {
        setShow(false);
        window.location.assign('https://www.cap-metiers.pro/');

    }

    const handleShowModal = () => setShow(true);

    const assignStepWizard = (instance) => {
        setStepWizard(instance);
    };

    const handleStepChange = (e) => {
        setActiveStep(e.activeStep - 1);
    };

    const handleComplete = async (merged) => {

        // use await to make the request
        handleShowModal();
        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_SOURCE}/api/v1.0/ResPartnerOdoo/request_membership2`, merged);
            console.log(response);
            await wait(2000);
            setLoading(false);
            if ((response.status === 200 && response.data.succeed === true)) {
                setDisplayTitle("Votre demande d'adhésion a bien été envoyée !");
            } else {
                setDisplayTitle("Une erreur est survenue lors de l'envoi de votre demande !");
                setDisplayMessage("Nous vous invitons à contacter le Pôle Offre de Formation à l'adresse <a href='mailto:tus@cap-metiers.pro'>tus@cap-metiers.pro</a> en indiquant le nom et le SIRET de votre structure. Nous reviendrons vers vous au plus vite. Veuillez nous excuser pour la gêne occasionnée");
            }
        } catch (error) {
            setLoading(false);
            setDisplayTitle("Une erreur est survenue lors de l'envoi de votre demande !");
            setDisplayMessage("Nous vous invitons à contacter le Pôle Offre de Formation à l'adresse <a href='mailto:tus@cap-metiers.pro'>tus@cap-metiers.pro</a> en indiquant le nom et le SIRET de votre structure. Nous reviendrons vers vous au plus vite. Veuillez nous excuser pour la gêne occasionnée");
        }
    };

    const assignStructure = (val) => {
        setStructure((structure) => ({
            ...structure,
            ...val
        }));
    };

    const assignMembershipInfos = (val) => {
        setMembershipInfos((infos) => ({
            ...infos,
            ...val
        }));
    };

    const assignFormTitle = (title) => {
        setFormTitle(title);
    }

    useEffect(() => {
        document.title = "Demande d'adhésion - CAP Métiers Nouvelle Aquitaine";


        if (activeStep === 0) {
            axios.get(`${process.env.REACT_APP_API_SOURCE}/api/v1.0/ResPartnerOdoo/Catalog/products`)
                .then(response => setCatalogProduct(response.data))
                .catch(error => console.error(error));


            axios.get(`${process.env.REACT_APP_API_SOURCE}/api/v1.0/ResPartnerOdoo/Catalog/functions`)
                .then(response => setFunctions(response.data))
                .catch(error => console.error(error));

        }
        if (activeStep === 1) {
            axios.get(`${process.env.REACT_APP_API_SOURCE}/api/v1.0/ResPartnerOdoo/Catalog/activities`)
                .then(response => setActivities(response.data))
                .catch(error => console.error(error));
        }

    }, [activeStep]);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12" >
                    <div className="logo-titre mt-2">
                        <img src="/logo-cap-metiers.png" alt="Logo Cap Métiers Nouvelle-Aquitaine" />
                        <h1> {formTitle}  </h1>
                    </div>
                    {/*<Button className="mb-3" href="/" variant="light"><KeyboardBackspace /></Button>{' '}*/}
                </div>
            </div>
            <div className="row">
                <div className="col-12" >
                    <span className="alert alert-info alert-info-referent">
                        <p><span>Avant de compléter ce formulaire, nous vous invitons à <strong>consulter&nbsp;la&nbsp;
                            <a href="https://sso.aquitaine-cap-metiers.fr/files/CHARTE_ADHESION.pdf" target="_blank" rel="noreferrer">charte d’adhésion</a></strong>
                            &nbsp;qui formalise votre engagement en tant qu’adhérent.<br />
                            Deux formules d’adhésions annuelles sont proposées :</span></p><ul>
                            <li>
                                <em>Une cotisation simple à 100 €,&nbsp;</em>
                            </li>
                            <li>
                                <em>Une cotisation premium à 150 €&nbsp;</em>
                                <span>
                                    qui permet, en complément, de disposer de 5 réservations de nos espaces de réunion sur
                                    le site de Pessac, hors accès à l’auditorium qui sera facturé forfaitairement par date de réservation au tarif de 250 €.
                                </span>
                            </li>
                        </ul>
                        <p>
                            <em>
                                Télécharger&nbsp;
                                <a href="https://sso.aquitaine-cap-metiers.fr/files/location_salles.pdf" target="_blank" rel="noreferrer">la plaquette de reservation de salle</a>.
                            </em>
                        </p>
                        <p><span>Les champs marqués d'une&nbsp;*&nbsp;sont obligatoires.</span></p>
                        <p>Pour toute question, vous pouvez&nbsp;
                            <a href="mailto:adhesion@cap-metiers.pro?subject=%5BDemande%20d'adh%C3%A9sion%5D%20Demande%20d'information%20">contacter le service adhésion</a>.
                        </p>
                        <em>
                        <p>Pour rappel: Si vous souhaitez <strong>référencer votre offre de formation et d’apprentissage</strong> dans la base régionale Rafael, <strong>l’adhésion n’est pas
                            nécessaire</strong>. Il vous suffit de contacter notre service dédié pour la gestion de l’offre et la préinscription :&nbsp;
                            <a href="mailto:offre@cap-metiers.pro?subject=R%C3%A9f%C3%A9rencement%20offre%20de%20formation">offre@cap-metiers.pro</a>.</p>
                        </em>
                        
                    </span>
                </div>
            </div>
            <Stepper
                styleConfig={
                    {
                        activeBgColor: '#3f1263',
                        activeTextColor: '#fff',
                        inactiveBgColor: '#cacaca',
                        inactiveTextColor: '#6c757d',
                        completedBgColor: '#3f1263',
                        completedTextColor: 'white',
                    }
                }
                activeStep={activeStep}             >
                <Step label="Indiquer votre SIRET  :" />
                <Step label="Votre souhait d'adhésion" />
                <Step label="Vérification des données de votre structure" />
            </Stepper>
            <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>
                <One formTitle={formTitle} formTitleCallback={assignFormTitle} structure={structure} structureCallback={assignStructure}  />
                <Two formTitle={formTitle} membershipInfos={membershipInfos} membershipInfosCallback={assignMembershipInfos} functions={functions} structure={structure} structureCallback={assignStructure} catalogProduct={catalogProduct} />
                <Three membershipInfos={membershipInfos} membershipInfosCallback={assignMembershipInfos} activities={activities} structure={structure}  completeCallback={handleComplete} />
            </StepWizard>
            <Modal
                show={show}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <div className="d-flex justify-content-center">
                        <Fade
                            in={loading}
                            style={{
                                transitionDelay: loading ? '800ms' : '0ms',
                            }}
                            unmountOnExit
                        >
                            <CircularProgress color="secondary" />
                        </Fade>
                    </div>
                    <div>
                        <h3 className="centred-title">
                            {displayTitle}
                        </h3>
                        <p className="help-message-error" dangerouslySetInnerHTML={{ __html: displayMessage }} />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Adhesion;

import Nav from 'react-bootstrap/Nav';
import {NavLink} from "react-bootstrap";

function NavBar() {
    return (
        <Nav className="justify-content-center">
            <Nav.Item>
                <NavLink href="/adhesion">Adhésion</NavLink>
            </Nav.Item>
            <Nav.Item>
                <NavLink href="/referencement">Référencement</NavLink>
            </Nav.Item>
            <Nav.Item>
                <NavLink href="/memori">MemOri</NavLink>
            </Nav.Item>
        </Nav>
    );
}

export default NavBar;